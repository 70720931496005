import { Booking, BookingType } from 'common';

export class BirthdayPartyBooking extends Booking {
    constructor() {
        super();
        this.type = BookingType.BIRTHDAY
    }

    themeId: number;
    childName: string;
    childAge: number;
    guestCount: number;

    override getCriterions(): Map<string, any> {
        return new Map()
            .set("themeId", this.themeId)
            .set("childAge", this.childAge)
            .set("guestCount", this.guestCount)
            .set("language", this.language);
    }
}
