import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Store, StoreService } from 'common';
import { ThemeService } from '../../../../services/themes-service';
import { BirthdayPartyBooking } from '../../../data/birthday-party-booking';
import { BirthdayTheme } from '../../../data/birthday-theme';

@Component({
    selector: 'app-birthday-party-recap',
    imports: [TranslateModule, CommonModule],
    templateUrl: './birthday-party-recap.component.html',
    styleUrl: './birthday-party-recap.component.scss'
})
export class BirthdayPartyRecapComponent implements OnInit {

  @Input({ required: true })
  booking: BirthdayPartyBooking;

  stores: Store[];
  theme: BirthdayTheme;
  get store() {
    return this.stores?.find(x => x.storeIdNumber === this.booking.storeIdentifier);
  };
  
  constructor(
    private storeSettingsSvc: ThemeService,
    private storeSvc: StoreService
  ) {}

  ngOnInit(): void {
    this.storeSvc.getAll({birthdayParties: true}).subscribe(
      x => this.stores = x
    );
    this.storeSettingsSvc.getTheme(this.booking.storeIdentifier, this.booking.themeId)
      .subscribe(x => this.theme = x);
  }

  computePrice() {
    let extraChildPricing = 0;
    let extraChildrenCount = this.booking.guestCount - this.theme.includedGuestCount;

    if (extraChildrenCount > 0) {
      extraChildPricing = extraChildrenCount * this.theme.guestPrice;
    }

    return this.theme.basePrice + extraChildPricing;
  }

}
