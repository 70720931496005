import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LegalCondition, McdoBookingFormComponent, Store, StoreService } from 'common';
import { forkJoin, Observable } from 'rxjs';
import { ThemeService } from '../../../services/themes-service';
import { BirthdayPartyBooking } from '../../data/birthday-party-booking';
import { BirthdayTheme } from '../../data/birthday-theme';
import { BirthdayPartyRecapComponent } from '../common/birthday-party-recap/birthday-party-recap.component';
import { BirthdayPartyBookingComponent } from './birthday-party-booking/birthday-party-booking.component';

@Component({
    selector: 'app-booking-form',
    imports: [McdoBookingFormComponent, BirthdayPartyBookingComponent, CommonModule, BirthdayPartyRecapComponent, TranslateModule, NgbTooltipModule],
    templateUrl: './booking-form.component.html',
    styleUrl: './booking-form.component.scss'
})
export class BookingFormComponent implements OnInit {

  stores: Store[];
  themes: BirthdayTheme[] = [];
  legalConditions: LegalCondition[];

  _booking: BirthdayPartyBooking;

  set booking(val : BirthdayPartyBooking) {
    this._booking = val;
    this.updateLegalsConditions();
  }

  get booking() : BirthdayPartyBooking {
    return this._booking;
  }

  constructor(
    private storeSvc: StoreService,
    private themeSvc: ThemeService,
    private translateSvc: TranslateService
  ) { }

  ngOnInit(): void {
    this.themeSvc.getThemes().subscribe(x => this.themes = x);
    this.storeSvc.getAll({birthdayParties: true}).subscribe(stores => this.stores = stores.filter(x => x.matchesCriteria));
    this.translateSvc.onLangChange.subscribe(x => this.updateLegalsConditions());
  }

  updateLegalsConditions(): void {
    const themeName = this.themes.find(x => x.id === this.booking.themeId)?.name;

    forkJoin({
      baseCondition: this.translateSvc.get("booking-form.confirmation.legal-conditions.conditions") as Observable<LegalCondition[]>,
      specificCondition: this.translateSvc.get(`booking-form.confirmation.legal-conditions.${themeName}-conditions`) as Observable<LegalCondition[]>
    }).subscribe(
      x => {
        this.legalConditions = [...x.baseCondition];
        if (typeof x.specificCondition !== "string" ) {
          this.legalConditions.push(...x.specificCondition);
        }
      })
  }

  calculatePrice(includedGuest: number, price: number, guestPrice: number) {
    let extraChildPricing = 0;
    let extraChildrenCount = this.booking.guestCount - includedGuest;

    if (extraChildrenCount > 0) {
      extraChildPricing = extraChildrenCount * guestPrice;
    }

    return price + extraChildPricing;
  }

}
