@if(showLegacyThemes()) {
    <p>{{ "global.theme.info.new-theme" | translate }}</p>
}
<div class="theme-container">
    @for (theme of availableThemes; track $index) {
        <div class="theme" (click)="setTheme(theme)">
            <div>
                <div class="theme-overlay" [class.selected-overlay]="currentTheme === theme">
                    <i class="bi bi-check-circle" aria-hidden="true"></i>
                </div>
                <img [src]="'assets/themes/' + theme.name + '.jpg'" [className]="theme.minAge > childAge ? 'disabled' : ''" />
            </div>
            <div
                style="text-align: center;"
                [style.font-weight]="currentTheme === theme ? 'bold' : 'unset'"
            >
                {{ 'global.theme.' + theme.name + '.long' | translate }}
            </div>
        </div>
    }
</div>

@if(showLegacyThemes()) {
    <p class="pt-2">{{ "global.theme.info.legacy-theme" | translate }}<p>
    <div class="theme-container">
        @for (theme of legacyThemes; track $index) {
            <div class="theme" (click)="setTheme(theme)">
                <div>
                    <div class="theme-overlay" [class.selected-overlay]="currentTheme === theme">
                        <i class="bi bi-check-circle" aria-hidden="true"></i>
                    </div>
                    <img [src]="'assets/themes/' + theme.name + '.jpg'" [className]="theme.minAge > childAge ? 'disabled' : ''" />
                </div>
                <div
                    style="text-align: center;"
                    [style.font-weight]="currentTheme === theme ? 'bold' : 'unset'"
                >
                    {{ 'global.theme.' + theme.name + '.long' | translate }}
                </div>
            </div>
        }
    </div>
}
