import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IntegerInputDirective } from 'common';
import { BirthdayPartyBooking } from '../../../data/birthday-party-booking';
import { BirthdayTheme } from '../../../data/birthday-theme';
import { ThemeSelectorComponent } from "./theme-selector/theme-selector.component";

@Component({
    selector: 'app-birthday-party-booking',
    imports: [ReactiveFormsModule, TranslateModule, ThemeSelectorComponent, IntegerInputDirective],
    templateUrl: './birthday-party-booking.component.html',
    styleUrl: './birthday-party-booking.component.scss'
})
export class BirthdayPartyBookingComponent implements OnInit {

  private _themes: BirthdayTheme[]
  isAuthenticated: boolean;
  maxAge: number;
  minAge: number;

  @Input({required: true})
  set themes(val: BirthdayTheme[]) {
    this._themes = val;
    if (this.themes.length !== 0) {
      this.minAge = this.themes.map(x => x.minAge).reduce((prev, curr) => prev < curr ? prev : curr);
      this.maxAge = this.themes.map(x => x.maxAge).reduce((prev, curr) => prev > curr ? prev : curr);
    }
  }

  get themes() {
    return this._themes;
  }

  @Output()
  onSubmit = new EventEmitter<BirthdayPartyBooking>();

  birthdayPartyForm = this.fb.group({
    name: this.fb.control<string>(null, { validators: Validators.required }),
    age: this.fb.control<number>(null, { validators: Validators.required }),
    theme: this.fb.control<BirthdayTheme>(null, { validators: Validators.required }),
    childrenCount: this.fb.control<number>(null, { validators: Validators.required }),
    language: this.fb.control<string>(null, { validators: Validators.required }),
    comment: this.fb.control<string>(null)
  });

  constructor(
    private fb: FormBuilder,
    private securityService: OidcSecurityService
  ) { }

  ngOnInit(): void {
    this.securityService.isAuthenticated().subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);
  }

  onFormSubmit() {
    if (this.birthdayPartyForm.valid) {
      const val = this.birthdayPartyForm.value;
      
      const birthdayPartyEvent = new BirthdayPartyBooking();
      birthdayPartyEvent.childName = val.name;
      birthdayPartyEvent.childAge = val.age;
      birthdayPartyEvent.themeId = val.theme.id;
      birthdayPartyEvent.guestCount = val.childrenCount;
      birthdayPartyEvent.language = val.language;
      birthdayPartyEvent.comment = val.comment;
      this.onSubmit.emit(birthdayPartyEvent);
    }
  }

}
