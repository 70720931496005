<div class="row border rounded p-3">
    <div class="col-md-6">
        <div class="row">
            <div class="col-4">
                <label class="form-label"> {{ 'recap.label.restaurant' | translate }}</label>
            </div>
            <div class="col-8">
                {{ store?.publicName }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.date' | translate }}</label>
            </div>
            <div class="col-8">
                {{ booking.date | date:'dd.MM.y' }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.slot' | translate }}</label>
            </div>
            <div class="col-8">
                {{ booking.date | date:'HH:mm' }} - {{ booking.endDate | date:'HH:mm' }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.childName' | translate }}</label>
            </div>
            <div class="col-8">
                {{ booking.childName }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.birthday' | translate }}</label>
            </div>
            <div class="col-8">
                {{ booking.childAge }} {{ 'recap.label.years' | translate }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.invitedKids' | translate }}</label>
            </div>
            <div class="col-8">
                {{ booking.guestCount }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.cake' | translate }}</label>
            </div>
            <div class="col-8">
                {{ 'recap.label.cakeName' | translate }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.theme' | translate }}</label>
            </div>
            <div class="col-8">
                {{ 'global.theme.' + theme.name + '.long' | translate }}
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.price' | translate }}</label>
            </div>
            <div class="col-8 text-danger">
                CHF {{ computePrice() }} *
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.language' | translate }}</label>
            </div>
            <div class="col-8">
                {{ 'global.language.' + booking.language | translate }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.contact.name' | translate }}</label>
            </div>
            <div class="col-8">
                {{ booking.contact?.firstName + ' ' + booking.contact?.lastName }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.contact.phone' | translate }}</label>
            </div>
            <div class="col-8">
                {{ booking.contact?.phoneNumber }}
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.contact.email' | translate }}</label>
            </div>
            <div class="col-8">
                {{ booking.contact?.email }}
            </div>
        </div>
    </div>
</div>

* {{ "recap.label.priceDisclaimer" | translate }}