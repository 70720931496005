import { Component } from '@angular/core';
import { BookingCancellationFormComponent } from 'common';
import { BirthdayPartyRecapComponent } from '../common/birthday-party-recap/birthday-party-recap.component';

@Component({
    selector: 'app-cancellation-form',
    imports: [BookingCancellationFormComponent, BirthdayPartyRecapComponent],
    templateUrl: './cancellation-form.component.html',
    styleUrl: './cancellation-form.component.scss'
})
export class CancellationFormComponent { }
